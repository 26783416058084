export default {
邮箱: "郵箱",
	手机号码: "手機號碼",
	密码: "密碼",
	登录: "登錄",
	还没账户: "還沒賬戶",
	忘记密码: "忘記密碼",
	立即注册: "立即註冊",
	HNFT账户登录: "HNFT賬戶登錄",
	请输入邮箱: "請輸入郵箱",
	邮箱格式错误: "郵箱格式錯誤",
	请输入手机号码: "請輸入手機號碼",
	请输入密码: "請輸入密碼",
	请选择: "請選擇",
	HNFT注册: "HNFT註冊",
	验证码: "驗證碼",
	推荐码: "推薦碼",
	确定: "確定",
	获取验证码: "獲取驗證碼",
	邮箱格式错误: "郵箱格式錯誤",
	请输入手机号码: "請輸入手機號碼",
	请输入邮箱: "請輸入郵箱",
	请输入正确的邮箱: "請輸入正確的郵箱",
	请输入密码: "請輸入密碼",
	登录密码只能为6至20位数字或字母: "登錄密碼只能為6至20位數字或字母",
	重置密码: "重置密碼",
	新密码: "新密碼",
	
	预售中: "預售中",
	交易列表: "交易列表",
	收藏品: "收藏品",
	限量: "限量",
	份: "份",
	用户指南: "用戶指南",
	什么是NFT: "什麼是NFT",
	首页: "首頁",
	发现: "發現",
	藏宝室: "藏寶室",
	我的: "我的",
	搜索您要的精彩: "搜索您要的精彩",
	我的收藏: "我的收藏",
	环球猎手顶级NFT艺术展览尽在HNFT: "環球獵手頂級NFT藝術展覽盡在HNFT",
	成为发行方: "成為發行方",
	成为发行方您可以在HNFT发行NFT: "成為發行方您可以在HNFT發行NFT",
	升级商家: "升級商家",
	成为商家您可以挂交易订单: "成為商家您可以掛交易訂單",
	公告头条: "公告頭條",
	关于发行: "關於發行",
	拍卖中心: "拍賣中心",
	铸造中心: "鑄造中心",
	发行机构: "發行機構",
	创作NFT: "創作NFT",
	"加入NFT发行方，您可以创建属于自己的NFT": "加入NFT發行方，您可以創建屬於自己的NFT",
	入驻NFT发行: "入駐NFT發行",
	开始: "開始",
	订单记录: "訂單記錄",
	全部: "全部",
	进行中: "進行中",
	已失效: "已失效",
	已完成: "已完成",
	预售倒计时: "預售倒計時",
	支付倒计时: "支付倒計時",
	份额: "份額",
	需支付: "需支付",
	立即支付: "立即支付",
	立即查看: "立即查看",
	订单号: "訂單號",
	升级商家: "升級商家",
	成为发行方: "成為發行方",
	"请输入USDT(TRC20)地址": "請輸入USDT(TRC20)地址",
	请输入USDT地址: "請輸入USDT地址",
	确认: "確認",
	取消: "取消",
	信息: "信息",
	我的收藏: "我的收藏",
	我的NFT: "我的NFT",
	余额: "餘額",
	充值: "充值",
	提现: "提現",
	我的资产: "我的資產",
	团队列表: "團隊列表",
	邀请会员: "邀請會員",
	账户绑定: "賬戶綁定",
	账户明细: "賬戶明細",
	联系客服: "聯繫客服",
	退出登录: "退出登錄",
	编辑资料: "編輯資料",
	头像: "頭像",
	昵称: "昵稱",
	账号: "賬號",
	推荐码: "推薦碼",
	安全设置: "安全設置",
	交易密码: "交易密碼",
	"昵称长度必须为5-15个字符": "昵稱長度必須為5-15個字符",
	提交: "提交",
	登录密码: "登錄密碼",
	新交易密码: "新交易密碼",
	消息列表: "消息列表",
	暂无信息: "暫無信息",
	充值记录: "充值記錄",
	没有更多了: "沒有更多了",
	审核通过: "審核通過",
	审核中: "審核中",
	驳回: "駁回",
	提现: "提現",
	开户姓名: "開戶姓名",
	提现金额: "提現金額",
	输入金额: "輸入金額",
	最大可提金额: "最大可提金額",
	提现记录: "提現記錄",
	团队列表: "團隊列表",
	注册时间: "註冊時間",
	我的资产: "我的資產",
	资产列表: "資產列表",
	Swap货币兑换: "Swap貨幣兌換",
	货币兑换: "貨幣兌換",
	兑换金额: "兌換金額",
	您最多可兑换: "您最多可兌換",
	余额不足: "餘額不足",
	立即兑换: "立即兌換",
	长按图片保存: "長按圖片保存",
	分享推广: "分享推廣",
	保存二维码: "保存二維碼",
	扫一扫来玩HNFT赚取收益: "掃一掃來玩HNFT賺取收益",
	我的邀请码: "我的邀請碼",
	立即分享给好友: "立即分享給好友",
	复制链接: "複製鏈接",
	复制邀请码: "複製邀請碼",
	请输入开户姓名: "請輸入開戶姓名",
	联系客服: "聯繫客服",
	在线客服: "在線客服",
	客服1: "客服1",
	客服2: "客服2",
	打开: "打開",
	我的收藏: "我的收藏",
	申请商家: "申請商家",
	确认入驻商家: "確認入駐商家",
	我同意: "我同意",
	升级请联系客服: "升級請聯繫客服",
	NFT搜索: "NFT搜索",
	请输入搜索关键词: "請輸入搜索關鍵詞",
	最新: "最新",
	最热: "最熱",
	创作NFT: "創作NFT",
	NFT文件: "NFT文件",
	NFT名称: "NFT名稱",
	简介: "簡介",
	请输入简介: "請輸入簡介",
	合集: "合集",
	点击选择主链: "點擊選擇主鏈",
	立即创建: "立即創建",
	申请须知: "申請須知",
	"申请NFT发行方需联系HNFT客服，申请成功，即可创建属于您自己的NFT作品!": "申請NFT發行方需聯繫HNFT客服，申請成功，即可創建屬於您自己的NFT作品!",
历史发行: "歷史發行",
	藏品类目: "藏品類目",
	盲盒类目: "盲盒類目",
	支付详情: "支付詳情",
	请输入支付密码: "請輸入支付密碼",
	预售倒计时: "預售倒計時",
	支付倒计时: "支付倒計時",
	余额: "餘額",
	订单号: "訂單號",
	购买类型: "購買類型",
	下单时间: "下單時間",
	预售订单: "預售訂單",
	支付时间: "支付時間",
	等待支付: "等待支付",
	已失效: "已失效",
	合计: "合計",
	立即支付: "立即支付",
	NFT详情: "NFT詳情",
	NFT信息: "NFT信息",
	预售价格: "預售價格",
	购买类型: "購買類型",
	预售订单: "預售訂單",
	开始时间: "開始時間",
	发行方信息: "發行方信息",
	作品特性: "作品特性",
	合约地址: "合約地址",
	作品描述: "作品描述",
	买入: "買入",
	卖出: "賣出",
	立即下单: "立即下單",
	持有数量: "持有數量",
	我要卖: "我要賣",
	升级商家: "升級商家",
	请输入卖出份数: "請輸入賣出份數",
	卖出NFT: "賣出NFT",
	获得金额: "獲得金額",
	出售: "出售",
	挂单数量: "掛單數量",
	拥有者: "擁有者",
	最少卖出: "最少賣出",
	我要买: "我要買",
	买入NFT: "買入NFT",
	请输入买入份数: "請輸入買入份數",
	需支付: "需支付",
	购买: "購買",
	最少买入: "最少買入",
	每份: "",
	确认下单: "確認下單",
	"预售进行中,下单完成后请等待预售结束,预售结束后需要您在24小时内支付下单的金额!": "預售進行中,下單完成後請等待預售結束,預售結束後需要您在24小時內支付下單的金額!",
	暂未对普通会员开放: "暫未對普通會員開放",
	'预售进行中,下单完成后请6小时内支付给商家!': '預售進行中,下單完成後請6小時內支付給商家!',
	限购数量: "限購數量",
	'申请商家需要持有HNFT Cash pledge，详情请联系官方客服或邮箱': '申請商家需持有HNFT Cash pledge，詳情請聯絡官方客服或郵箱',
	质押HNFT: '質押HNFT',
	质押记录: '質押記錄',
	赎回成功: '贖回成功',
	进行中: '進行中',
	审核中: '審核中',
	质押失败: '質押失敗',
	解除质押: '解除質押',
	奖励: '獎勵',
	创建时间: '創建時間',
	结束时间: '結束時間',
	赎回: '贖回',
	HNFT名称: 'HNFT名稱',
	预售价格: '預售價格',
	质押数量: '質押數量',
	请输入数量: '請輸入數量',
	全部: '全部',
	可用数量: '可用數量',
	'碎片奖励(天)': '碎片獎勵(天)',
	备注: '備註',
	质押时间: '質押時間',
	计息时间: '計息時間',
	赎回时间: '贖回時間',
	立即提交: '立即提交',
	'时间(天)': '時間(天)',
	质押确认: '質押確認',
	'质押您可以获得版税和手续费奖励，质押不可取消，请确认!': '質押您可以獲得版稅和手續費獎勵，質押不可取消，請確認!',
	持有: '持有',
	质押: '質押',
	价格趋势: '價格趨勢',
	持仓市值: '持倉市值',
	今日盈亏: '今日盈虧',
	总盈亏: '總盈虧',
	持仓成本: '持倉成本',
	碎片数量: '碎片數量',
	质押碎片: '質押碎片',
	质押收入: '質押收入',
	名称: '名稱',
	实时价: '即時價',
	市值: '市值',
	质押: '質押',
	持仓: '持倉',
	我的持仓: '我的持倉',
	查看: '查看',
	实名认证: '實名認證',
	请等待实名审核: '請等待實名審核',
	确认: '確認',
	注意事项: '注意事項',
	'需持有HNFT Handling fee数量': '需持有HNFT Handling fee數量',
	上传身份证明: '上傳身份證明',
	上传持有凭证: '上傳持有憑證',
	历史: '歷史',
	操作: '操作',
	
	交易: '交易',
	一键买入: '一鍵買入',
	一键卖出: '一鍵賣出',
	最低回购价: '最低回購價',
	一键买卖: '一鍵買賣',
	买入价格: '買入價格',
	预估回购价格: '預估回購價格',
	回购价格比例: '回購價格比例',
	买入数量: '買入數量',
	请输入买入数量: '請輸入買入數量',
	卖出数量: '賣出數量',
	请输入卖出数量: '請輸入賣出數量',
	回购价格: '回購價格',
	数量不足: '數量不足',
	信用分: '信用分',
	使用版税兑换: '使用版稅兌換',
	'使用HNFT Royalties兑换': '使用HNFT Royalties兌換',
	'需支付版税/碎片': '需支付版稅/碎片',
	'OTC只支持USDT(ERC)选项': 'OTC只支持USDT(ERC)選項',
	进行中: '進行中',
	请联系右上角与商家沟通交易: '請聯繫右上角與商家溝通交易',
	订单已完成: '訂單已完成',
	订单已取消: '訂單已取消',
	交易信息: '交易信息',
	单价: '單價',
	购买数量: '購買數量',
	支付金额: '支付金額',
	商户名称: '商戶名稱',
	订单ID: '訂單ID',
	时间: '時間',
	类型: '類型',
	转入地址: '轉入地址',
	进行中: '進行中',
	已取消: '已取消',
	已完成: '已完成',
	OTC交易: 'OTC交易',
	'HNFT的OTC交易对接的是主流交易所商家，为您提供便利的法币交易需求。': 'HNFT的OTC交易對接的是主流交易所商家，為您提供便利的法幣交易需求。',
	OTC钱包: 'OTC錢包',
	卖出到OTC余额: '賣出到OTC餘額',
	OTC余额支付: 'OTC餘額支付',
	OTC余额: 'OTC餘額',
	C2C转账: 'C2C轉賬',
	C2C需要您手动转账: 'C2C需要您手動轉賬',
	确认支付: '確認支付',
	'OTC-Pay将使用您的OTC余额支付，请确认！': 'OTC-Pay將使用您的OTC餘額支付，請確認！',
	OTC快捷交易: 'OTC快捷交易',
	快捷交易能够让您以当前OTC市场中的最优价格成交: '快捷交易能夠讓您以當前OTC市場中的最優價格成交',
购买: '購買',
	出售: '出售',
	我要支付: '我要支付',
	我将收到: '我將收到',
	参考价格: '參考價格',
	如何使用OTC: '如何使用OTC',
	OTC记录: 'OTC記錄',
	交易数量: '交易數量',
	请先KYC再进行OTC操作: '請先KYC再進行OTC操作',
	上传身份证正面: '上傳身份證正面',
	上传身份证反面: '上傳身份證反面',
	请上传身份证正面: '請上傳身份證正面',
	请上传身份证反面: '請上傳身份證反面',
	交易对象ID: '交易對象ID',
	请选择支付币种: '請選擇支付幣種',
	买入碎片数量: '買入碎片數量',
	交易金额: '交易金額',
	五分钟前: '五分鐘前',
	'祝贺您收到幸运订单，请查看！': '祝賀您收到幸運訂單，請查看！',
	'OTC验证金额': 'OTC驗證金額',
	升级商家: '升級商家',
	请联系客服: '請聯繫客服',
	确认入驻商家: '確認入駐商家',
	我同意: '我同意',
	成为发行方: '成為發行方',
	成为发行方您可以发行属于您自己的NFT: '成為發行方您可以發行屬於您自己的NFT',
	NFT发行方: 'NFT發行方',
	'申请成为NFT发行方，需要您有相应的区块链合法资质，详情请联系官方客服或邮箱': '申請成為NFT發行方，需要您有相應的區塊鏈合法資質，詳情請聯繫官方客服或郵箱',
	'您的OTC钱包需持有': '您的OTC錢包需持有',
	
	指数交易: '指數交易',
	未成交: '未成交',
	进行中: '進行中',
	已完成: '已完成',
	强制平仓: '強制平倉',
	撤单: '撤單',
	当前委托: '當前委託',
	历史委托: '歷史委託',
	买入: '買入',
	卖出: '賣出',
	倍杠杆: '倍槓桿',
	保证金: '保證金',
	开仓价: '開倉價',
	当前价: '當前價',
	持仓数量: '持倉數量',
	预计盈亏: '預計盈虧',
	创建时间: '創建時間',
	止损价: '止損價',
	止盈价: '止盈價',
	手续费: '手續費',
	添加保证金: '添加保證金',
	平仓: '平倉',
	立即下单: '立即下單',
	平仓价格: '平倉價格',
	平仓时间: '平倉時間',
	买涨: '買漲',
	买跌: '買跌',
	OTC余额: 'OTC餘額',
	杠杆: '槓桿',
	'NFT-GI预估数量': 'NFT-GI預估數量',
	预估手续费: '預估手續費',
	'买入/开多': '買入/開多',
	'卖出/开空': '賣出/開空',
	设置价格: '設置價格',
	请输入价格: '請輸入價格',
	请输入增加的保证金: '請輸入增加的保證金',
	请输入买入USDT金额: '請輸入買入USDT金額',
	最低增加保证金为10USDT: '最低增加保證金為10USDT',
	订单信息: '訂單信息',
	'您是否立即撤单，撤单不可恢复，请确认!': '您是否立即撤單，撤單不可恢復，請確認!',
	'立即按照当前价格平仓，请确认!': '立即按照當前價格平倉，請確認!',
	'最小交易金额为100USDT': '最小交易金額為100USDT',
	盈亏: '盈虧',
	NFT指数: 'NFT指數',
	交易明细: '交易明細',
	
	NFT借贷: 'NFT借贷',
	借贷: '借贷',
	借贷入口: '借贷入口',
	还贷入口: '还贷入口',
	借款申请: '借款申请',
	借款金额: '借款金额',
	'请输入申请金额(USDT)': '请输入申请金额(USDT)',
	账户: '账户',
	申请将获取您的账户信息: '请输入邮箱',
	立即申请: '立即申请',
	借款记录: '借款记录',
	借款金额: '借款金额',
	创建时间: '创建时间',
	完成: '完成',
	进行中: '进行中',
	联系客服获取借款合同: '联系客服获取借款合同',
	OTC还贷: 'OTC还贷',
	'该交易将为您购买的USDT,直接还款': '该交易将为您购买的USDT,直接还款',
	我要还款: '我要还款',
	'当前账户未实名，是否立即实名认证。': '当前账户未实名，是否立即实名认证。',
	请输入验证码: '请输入验证码',
	请输入邀请码: '请输入邀请码',
	请输入新密码: '请输入新密码',
	我的服务: '我的服务',
	'当前账户未实名，是否立即实名认证。': '当前账户未实名，是否立即实名认证。',
}