export default {
	邮箱: "Mail",
	手机号码: "Phone",
	密码: "Password",
	登录: "Log in",
	还没账户: "Don't have an account yet",
	忘记密码: "Forgot password",
	立即注册: "Sign up now",
	HNFT账户登录: "HNFT account login",
	请输入邮箱: "Please input your email",
	邮箱格式错误: "Email format error",
	请输入手机号码: "Please enter the phone number",
	请输入密码: "Please enter password",
	请选择: "Please choose",
	HNFT注册: "HNFT registration",
	验证码: "Verification code",
	推荐码: "Referral code",
	确定: "Confirm",
	获取验证码: "verification code",
	邮箱格式错误: "Email format error",
	请输入手机号码: "Please enter the phone number",
	请输入邮箱: "Please input your email",
	请输入正确的邮箱: "Please enter your vaild email",
	请输入密码: "Please enter password",
	登录密码只能为6至20位数字或字母: "The login password can only be 6 to 20 digits or letters",
	重置密码: "Reset Password",
	新密码: "New password",
	预售中: "Pre-sale",
	交易列表: "Transaction list",
	收藏品: "Collection",
	限量: "Limited",
	份: "",
	用户指南: "User Guide",
	什么是NFT: "What is NFT",
	首页: "Home",
	发现: "Find",
	藏宝室: "Collect",
	我的: "Mine",
	搜索您要的精彩: "Search what you want",
	我的收藏: "My collection",
	环球猎手顶级NFT艺术展览尽在HNFT: "Global Hunter's Top NFT Art Exhibition at HNFT",
	成为发行方: "Be the issuer",
	成为发行方您可以在HNFT发行NFT: "Become an issuer and you can issue NFTs on HNFT",
	升级商家: "Upgrade business",
	成为商家您可以挂交易订单: "Become a merchant and you can place transaction orders",
	公告头条: "Announcement headlines",
	关于发行: "About distribution",
	拍卖中心: "Auction Center",
	铸造中心: "Foundry center",
	发行机构: "Issuer",
	创作NFT: "Create NFTs",
	"加入NFT发行方，您可以创建属于自己的NFT": "Join the NFT issuer, you can create your own NFT",
	入驻NFT发行: "Settled in NFT issuance",
	开始: "Start",
	订单记录: "Order History",
	全部: "All",
	进行中: "In progress",
	已失效: "Deactivated",
	已完成: "Completed",
	预售倒计时: "Pre-sale countdown",
	支付倒计时: "Payment Countdown",
	份额: "Quantity",
	需支付: "Payable",
	立即支付: "Pay Now",
	立即查看: "View now",
	订单号: "Order id",
	升级商家: "Merchant",
	成为发行方: "Issuer",
	"请输入USDT(TRC20)地址": "Please enter USDT(TRC20) address",
	请输入USDT地址: "Please enter USDT address",
	确认: "Confirm",
	取消: "Cancel",
	信息: "Information",
	我的收藏: "My collection",
	我的NFT: "My NFT",
	余额: "Balance",
	充值: "Recharge",
	提现: "Withdraw",
	我的资产: "My assets",
	团队列表: "Team list",
	邀请会员: "Invite members",
	账户绑定: "Account binding",
	账户明细: "Account details",
	联系客服: "Contact Customer Service",
	退出登录: "sign out",
	编辑资料: "Edit information",
	头像: "Avatar",
	昵称: "Nickname",
	账号: "Account",
	推荐码: "Referral code",
	安全设置: "Security Settings",
	交易密码: "Transaction password",
	"昵称长度必须为5-15个字符": "Nickname must be 5-15 characters long",
	提交: "Submit",
	登录密码: "Login password",
	新交易密码: "New transaction password",
	消息列表: "Message list",
	暂无信息: "No information",
	类别: "Category",
	点击选择: "Click to select",
	地址: "Address",
	钱包充值: "Wallet recharge",
	输入金额: "Enter amount",
	上传凭证: "Upload certificate",
	复制成功: "Copy successfully",
	金额错误: "Wrong amount",
	请上传凭证: "Please upload credentials",
	充值记录: "Recharge record",
	没有更多了: "No more",
	审核通过: "Approved",
	审核中: "Under review",
	驳回: "Rejected",
	提现: "Withdraw",
	开户姓名: "Name",
	提现金额: "Withdrawal Amount",
	输入金额: "Enter amount",
	最大可提金额: "Maximum amount",
	提现记录: "Withdrawals record",
	团队列表: "Team list",
	注册时间: "Time",
	我的资产: "My assets",
	资产列表: "Asset List",
	Swap货币兑换: "Swap currency exchange",
	货币兑换: "Currency exchange",
	兑换金额: "Exchange amount",
	您最多可兑换: "You can redeem up to",
	余额不足: "Insufficient balance",
	立即兑换: "Redeem now",
	长按图片保存: "Long press the picture to save",
	分享推广: "Share promotion",
	保存二维码: "Save QR code",
	扫一扫来玩HNFT赚取收益: "Scan to play HNFT to earn money",
	我的邀请码: "My invitation code",
	立即分享给好友: "Share with friends now",
	复制链接: "Copy Link",
	复制邀请码: "Copy the invitation code",
	请输入开户姓名: "Please enter the account name",
	账户余额: "Account Balance",
	收入: "income",
	支出: "spending",
	联系客服: "Contact Customer Service",
	在线客服: "online service",
	客服1: "customer service 1",
	客服2: "customer service 2",
	打开: "OPEN",
	我的收藏: "My Favorites",
	申请商家: "Apply for business",
	确认入驻商家: "Confirm the settled business",
	我同意: "I agree",
	升级请联系客服: "Please contact customer service for upgrade",
	NFT搜索: "NFT search",
	请输入搜索关键词: "Please enter the search keyword",
	最新: "latest",
	最热: "hottest",
	创作NFT: "Create NFTs",
	NFT文件: "NFT file",
	NFT名称: "NFT name",
	简介: "Introduction",
	请输入简介: "Please enter a profile",
	合集: "Collection",
	点击选择主链: "Click to select main chain",
	立即创建: "Create now",
	申请须知: "Application Instructions",
	"申请NFT发行方需联系HNFT客服，申请成功，即可创建属于您自己的NFT作品!": "To apply for the NFT issuer, you need to contact HNFT customer service. If the application is successful, you can create your own NFT work!",
	历史发行: "History",
	藏品类目: "Collection",
	盲盒类目: "Blind box",
	支付详情: "Payment Details",
	请输入支付密码: "Please enter payment password",
	预售倒计时: "Pre-sale countdown",
	支付倒计时: "Payment countdown",
	订单号: "Order ID",
	购买类型: "Purchase type",
	下单时间: "Order time",
	预售订单: "Pre-order",
	支付时间: "Payment time",
	等待支付: "Wait for payment",
	已失效: "Expired",
	合计: "Total",
	立即支付: "Pay immediately",
	NFT详情: "NFT details",
	NFT信息: "NFT information",
	预售价格: "Pre-sale price",
	购买类型: "Purchase Type",
	预售订单: "Pre-order",
	开始时间: "Starting time",
	发行方信息: "Issuer information",
	作品特性: "Features",
	合约地址: "Contract address",
	作品描述: "Description",
	买入: "Buy",
	卖出: "Sell",
	立即下单: "Order now",
	持有数量: "Number of holdings",
	我要卖: "Sell",
	升级商家: "Merchant",
	请输入卖出份数: "Please enter the number of copies sold",
	卖出NFT: "Sell NFT",
	获得金额: "Get Amount",
	出售: "sale",
	挂单数量: "Quantity",
	拥有者: "owner",
	最少卖出: "Least Sell",
	我要买: "Buy",
	买入NFT: "Buy NFTs",
	请输入买入份数: "Please enter the number of purchases",
	需支付: "Payable",
	购买: "Buy",
	最少买入: "Minimum buy",
	每份: "1",
	确认下单: "Confirm order",
	"预售进行中,下单完成后请等待预售结束,预售结束后需要您在24小时内支付下单的金额!": "The pre-sale is in progress, please wait for the pre-sale to end after the order is completed. After the pre-sale ends, you need to pay the amount of the order within 24 hours!",
	暂未对普通会员开放: "Not yet open to regular members",
	限购数量: "Quantity limit",
	
	数量: "Quantity",
	剩余: "Surplus",
	发布方信息: "Publisher information",
	挂单: "Pending order",
	交易订单: "Trade order",
	当前持有: "Currently held",
	NFT总份数: "Total number of NFTs",
	选择买入或卖出: "Choose to buy or sell",
	请输入挂单价格: "Please enter the pending order price",
	请输入数量: "Please enter the quantity",
	最低: "Lowest",
	请输入最低份数: "Please enter a minimum number of copies",
	最高: "Highest",
	请输入最高份数: "Please enter the maximum number of copies",
	交易通知邮箱: "Transaction notification mailbox",
	请输入邮箱: "Please input your email",
	类型: "Type",
	价格: "Price",
	立即提交: "Submit now",
	撤销: "Revoke",
	已撤销: "Revoked",
	已完成: "Completed",
	支付金额: "Payment amount",
	地址: "Address",
	凭证: "Certificate",
	买入订单: "Buy order",
	卖出订单: "Sell order",
	支付货币: "Payment currency",
	状态: "State",
	确认下单: "Surplus",
	'预售进行中,下单完成后请6小时内支付给商家!': "The pre-sale is in progress, please pay to the merchant within 6 hours after placing the order!",
	参数错误: "Parameter error",
	该操作需要申请为HNFT商家: "This operation needs to apply as a HNFT merchant",
	碎片化顶级NFT艺术展览尽在HNFT: "Fragmented top NFT art exhibitions are all in HNFT",
	NFT碎片: "NFT Fragments",
	将您持有的NFT分布成碎片: "Distribute the NFT you hold into shards",
	成为商家: "Become a merchant",
	成为商家您可以将您持有的NFT拆分: "Become a merchant and you can split the NFT you hold",
	入驻HNFT商家: "Settled in HNFT merchants",
	申请须知: "Application Notes",
	请提交区块链应用合法执照或者证件: "Please submit the legal license or certificate of blockchain application",
	开始: "Start",
	关于NFT: "About NFT",
	创作中心: "Creation Center",
	合成中心: "Synthesis center",
	HNFT商家: "Merchant",
	HNFT碎片: "Fragments",
	碎片: "Fragments",
	版税: "Royalties",	
	版税兑换: "Exchange",
	转移NFT: "Transfer NFT",
	转入NFT: "Transfer to NFT",
	版税明细: "Royalty Details",
	联系邮箱: "Contact email",
	客服邮箱: "Customer Service Email",
	申请成为商家: "Apply to be a merchant",	
	公司名称: "Company Name",	
	请输入公司: "Please enter company",
	国家: "Country",	
	请输入国家: "Please enter country",	
	营业执照: "Business license",	
	法人证件正面: "Front side of legal person certificate",	
	法人证件反面: "The reverse side of the legal person certificate",	
	请等待审核: "Please wait for review",	
	请输入正确参数: "Please enter the correct parameters",	
	请上传完整图片: "Please upload full picture",	
	申请商家须知: "Notice for applying for merchants",	
	请提交区块链应用合法执照或者证件: "Please submit the legal license or certificate of blockchain application",	
	我的碎片: "My pieces",
	持有: "hold",
	合成: "Synthesis",
	碎片数量不足: "Insufficient number of shards",
	合成碎片: "Synthetic Fragments",
	'合成碎片不可取消,是否确认合成': "Combining fragments cannot be cancelled, whether to confirm the synthesis",
	兑换NFT: "Redeem NFT",
	请输入兑换份数: "Please enter the number of copies",
	需支付版税: "Royalties are payable",
	兑换: "Exchange",
	点击选择NFT: "Click to select NFT",
	请输入地址: "Please enter address",
	转移记录: "Transfer record",
	NFT转入记录: "NFT transfer record",
	NFT转入: "NFT transfer in",
	请输入名称: "Please enter a name",
	'请输入特性,请使用逗号隔开': "Please enter the characteristics, please use commas to separate",
	请输入描述: "Please enter a description",
	上传NFT文件: "Upload NFT file",
	上传转入凭证: "Upload transfer certificate",
	请上传NFT文件: "Please upload the NFT file",
	请输入交易密码: "Please enter the transaction password",
	'暂未设置交易密码,请设置交易密码': "No transaction password has been set yet, please set a transaction password",
	地址修改: "Address modification",
	'扫一扫来玩HNFT,交易0版税,推荐更有版税佣金': "Scan to play HNFT, 0 royalties for transactions, and more royalties for recommendations",
	忘记交易密码: "Royalties",
	重复密码: "Royalties",
	新登录密码: "Royalties",
	重复登录密码: "Royalties",
	查看该作者: "View the author",
	待确认: "To be confirmed",
	立即确认: "Confirm now",
	请等待确认: "Please wait for confirmation",
	等待确认: "Waiting for confirmation",
	提示: "Hint",
	'您已确认无误收到款项，是否确认': "You have confirmed that you have received the payment without error, are you sure?",
	点击选择NFT文件: "Click to select NFT file",
	请输入拆分份额: "Please enter the split share",
	限购: "Purchase limit",
	每人限购份额: "Purchase limit per person",
	币种: "Currency",
	点击选择币种: "Click to select currency",
	请输入价格: "Please enter a price",
	'倒计时(小时)': "Countdown (hours)",
	点击选择倒计时: "Click to select countdown",
	TG聊天链接: "TG chat link",
	请输入链接: "Please enter link",
	Line聊天链接: "Line chat link",
	WhatsApp聊天链接: "WhatsApp chat link",
	制作NFT碎片: "Make NFT fragments",
	发布记录: "Release record",
	信息: "Information",
	您需要支付: "You need to pay ",
	'未收到款?': "Not received payment?",
	'您未收到款,客服将在2小时内处理您的订单,立即申请客服处理!': "You have not received the payment, the customer service will process your order within 2 hours, apply for customer service processing immediately!",
	联系商家: "Contact merchant",
	倒计时: "Countdown",
	卖出范围: "Selling range",
	买入范围: "Buying range",
	该操作需要申请为HNFT商家: "This operation requires applying as a HNFT merchant",
	'申请商家需要持有HNFT Cash pledge，详情请联系官方客服或邮箱': 'Applicants need to hold a HNFT Cash pledge. For details, please contact official customer service or email.',
	质押HNFT: 'Staking HNFT',
	质押记录: 'Pledge record',
	赎回成功: 'Redemption successful',
	进行中: 'In progress',
	审核中: 'Under review',
	质押失败: 'Pledge failed',
	解除质押: 'Unpledge',
	奖励: 'Award',
	创建时间: 'Creation time',
	结束时间: 'End Time',
	赎回: 'Redemption',
	HNFT名称: 'HNFT name',
	预售价格: 'Pre-sale price',
	质押数量: 'Pledge quantity',
	请输入数量: 'Please enter quantity',
	全部: 'All',
	可用数量: 'Quantity Available',
	'碎片奖励(天)': 'Fragment reward (days)',
	备注: 'Remark',
	质押时间: 'Pledge time',
	计息时间: 'Interest accrual time',
	赎回时间: 'Redemption time',
	立即提交: 'Submit now',
	'时间(天)': 'Time(day)',
	质押确认: 'Pledge confirmation',
	'质押您可以获得版税和手续费奖励，质押不可取消，请确认!': 'You can get royalties and handling fee rewards by staking. The pledge is non-cancellable, please confirm!',
	持有: 'Hold',
	质押: 'Pledge',
	价格趋势: 'Price trend',
	持仓市值: 'Position market value',
	今日盈亏: 'Today profit and loss',
	总盈亏: 'Total profit and loss',
	持仓成本: 'Holding cost',
	碎片数量: 'Number of fragments',
	质押碎片: 'Pledge shards',
	质押收入: 'Pledge income',
	名称: 'Name',
	实时价: 'Price',
	市值: 'Market value',
	质押: 'Pledge',
	持仓: 'Position',
	我的持仓: 'My positions',
	查看: 'Check',
	实名认证: 'Verified',
	请等待实名审核: 'Please wait for the real-name review',
	确认: 'Confirm',
	注意事项: 'Precautions',
	'需持有HNFT Handling fee数量': 'The amount of HNFT Handling fee required to be held',
	上传身份证明: 'Upload proof of identity',
	上传持有凭证: 'Upload holding certificate',
	历史: 'History',
	操作: 'Operate',
	
	交易: ' trade',
	一键买入: 'Quick Buy',
	一键卖出: 'Quick Sell',
	最低回购价: 'Repurchase price',
	一键买卖: 'One-click buying and selling',
	买入价格: 'Buying price',
	预估回购价格: 'Estimated buyback price',
	回购价格比例: 'Repurchase price ratio',
	买入数量: 'Buy quantity',
	请输入买入数量: 'Please enter the purchase quantity',
	卖出数量: 'Sell quantity',
	请输入卖出数量: 'Please enter the selling quantity',
	回购价格: 'Buyback price',
	数量不足: 'Insufficient quantity',
	信用分: 'Credit score',
	使用版税兑换: 'Use Royalty Redemption',
	'使用HNFT Royalties兑换': 'Redeem with HNFT Royalties',
	'需支付版税/碎片': 'Royalties/Fragments Payable',
	'OTC只支持USDT(ERC)选项': 'OTC only supports USDT(ERC) option',
	进行中: 'In progress',
	请联系右上角与商家沟通交易: 'Please contact the upper right corner to communicate with the merchant for transactions',
	订单已完成: 'Order completed',
	订单已取消: 'Order canceled',
	交易信息: 'Trading Information',
	单价: 'Unit price',
	购买数量: 'Purchase quantity',
	支付金额: 'Payment amount',
	商户名称: 'Business Name',
	订单ID: 'Order ID',
	时间: 'Time',
	类型: 'Type',
	转入地址: 'Transfer-to address',
	进行中: 'In progress',
	已取消: 'Cancelled',
	已完成: 'Completed',
	OTC交易: 'OTC trading',
	'HNFT的OTC交易对接的是主流交易所商家，为您提供便利的法币交易需求。': 'HNFT OTC transactions are connected to mainstream exchange merchants, providing you with convenient legal currency transaction needs.',
	OTC钱包: 'OTC wallet',
	卖出到OTC余额: 'Sell to OTC balance',
	OTC余额支付: 'OTC balance payment',
	OTC余额: 'OTC balance',
	C2C转账: 'C2C transfer',
	C2C需要您手动转账: 'C2C requires you to transfer money manually',
	确认支付: 'C2C requires you to transfer money manually',
	'OTC-Pay将使用您的OTC余额支付，请确认！': 'OTC-Pay will use your OTC balance to pay, please confirm!',
	OTC快捷交易: 'OTC quick transaction',
	快捷交易能够让您以当前OTC市场中的最优价格成交: 'Quick trading allows you to complete transactions at the best price in the current OTC market',
	购买: 'Buy',
	出售: 'Sell',
	我要支付: 'I want to pay',
	我将收到: 'I will receive',
	参考价格: 'Proposed price',
	如何使用OTC: 'How to use OTC',
	OTC记录: 'OTC records',
	交易数量: 'Number of transactions',
	请先KYC再进行OTC操作: 'Please KYC first before proceeding with OTC operations',
	上传身份证正面: 'Upload the front of your ID card',
	上传身份证反面: 'Upload the reverse side of ID card',
	请上传身份证正面: 'Please upload the front side of your ID card',
	请上传身份证反面: 'Please upload the reverse side of your ID card',
	交易对象ID: 'Transaction object ID',
	请选择支付币种: 'Please select payment currency',
	买入碎片数量: 'Number of pieces purchased',
	交易金额: 'transaction',
	五分钟前: 'five minutes ago',
	'祝贺您收到幸运订单，请查看！': 'Congratulations on your lucky order, please check it out!',
	'OTC验证金额': 'OTC verification amount',
	升级商家: 'Upgrade merchant',
	请联系客服: 'Please contact customer service',
	确认入驻商家: 'Confirm merchants settled in',
	我同意: 'I agree',
	成为发行方: 'Become an issuer',
	成为发行方您可以发行属于您自己的NFT: 'Become an issuer and you can issue your own NFT',
	NFT发行方: 'NFT issuer',
	'申请成为NFT发行方，需要您有相应的区块链合法资质，详情请联系官方客服或邮箱': 'To apply to become an NFT issuer, you need to have the corresponding legal qualifications for blockchain. For details, please contact official customer service or email',
	'您的OTC钱包需持有': 'Your OTC wallet needs to hold',
	指数交易: 'Index Trading',
	未成交: 'Unsold',
	进行中: 'In Progress',
	已完成: 'Completed',
	强制平仓: 'Forced Liquidation',
	撤单: 'Cancel Order',
	当前委托: 'Current',
	历史委托: 'Records',
	买入: 'BUY',
	卖出: 'SELL',
	倍杠杆: 'Leverage',
	保证金: 'Margin',
	开仓价: 'Opening Price',
	当前价: 'Current Price',
	持仓数量: 'Quantity',
	预计盈亏: 'Expected Profit and Loss',
	创建时间: 'Date of Creation',
	止损价: 'Stop Loss',
	止盈价: 'Take Profit',
	手续费: 'Handling Fee',
	添加保证金: 'Add Margin',
	平仓: 'Close the Position',
	立即下单: 'Create Order',
	平仓价格: 'Closing Price',
	平仓时间: 'Time of Completion',
	买涨: 'BUY UP',
	买跌: 'BUY THE DIP',
	OTC余额: 'OTC Balance',
	杠杆: 'Lever',
	'NFT-GI预估数量': 'NFT-GI Estimated Quantity',
	预估手续费: 'Estimated Handling Fee',
	'买入/开多': 'BUY / Open Long',
	'卖出/开空': 'SELL / Going Short',
	设置价格: 'Set an Amount',
	请输入价格: 'Enter an Amount',
	请输入增加的保证金: 'Enter the Additional Margin',
	请输入买入USDT金额: 'Enter the USDT amount to purchase',
	最低增加保证金为10USDT: 'The minimum additional margin is 10USDT',
	订单信息: 'Order Information',
	'您是否立即撤单，撤单不可恢复，请确认!': 'Do you want to cancel the order now? Cancellation is irreversible. Please confirm!',
	'立即按照当前价格平仓，请确认!': 'Closing the position at the current price, please confirm!',
	'最小交易金额为100USDT': 'The minimum amount is 100 USDT',
	'盈亏': 'Profit and Loss',
	 NFT指数: 'NFT Index',
	 交易明细: 'Transaction Details',
	NFT借贷: 'NFT Lending',
	借贷: 'Borrowing',
	借贷入口: 'Loan Entry',
	还贷入口: 'Loan repayment entrance',
	借款申请: 'Loan Application',
	借款金额: 'Loan amount',
	'请输入申请金额(USDT)': 'Please enter the application amount (USDT)',
	账户: 'Account',
	申请将获取您的账户信息: 'The application will obtain your account information',
	立即申请: 'Apply immediately',
	借款记录: 'Loan record',
	借款金额: 'Loan amount',
	创建时间: 'Creation time',
	完成: 'Completed',
	进行中: 'in progress',
	联系客服获取借款合同: 'Contact customer service to obtain a loan agreement',
	OTC还贷: 'OTC loan repayment',
	'该交易将为您购买的USDT,直接还款': 'This transaction will directly repay the USDT you purchased',
	我要还款: 'I want to repay',
	请输入验证码: 'Please enter verification code',
	请输入邀请码: 'Please enter the invitation code',
	请输入新密码: 'Please enter a new password',
	我的服务: 'My Services',
	'当前账户未实名，是否立即实名认证。': 'The current account is not authenticated. Do you want to authenticate the account immediately?',
}